import React, { useState, useEffect } from 'react';
import FsLightbox from 'fslightbox-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import {
	img1,
	img2,
	img3,
	img4,
	img5,
	img6,
	img7,
	img8,
	img9,
	img10,
	img11,
	img12,
	img13,
	img14,
	img15,
	img16,
	img17,
	img18,
	img19,
	img20,
	img21,
	img22,
	imgPlan
} from './img';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import santa from './img/santa2.jpeg';

const images = [
	img1,
	img2,

	img4,
	img5,
	img6,
	img7,
	img8,
	img9,
	img10,
	img11,
	img12,
	img13,
	img14,
	img15,
	img16,
	img17,
	img18,
	img19,
	img20,
	img21,
	img22,
	img3,
	imgPlan
];

const Sjomansgatan = ({ isSignedIn }) => {
	const [ toggler, setToggler ] = useState(false);
	const [ currentIndex, setCurrentIndex ] = useState(1);

	const handleClick = (index) => {
		setCurrentIndex(index);
		setToggler(!toggler);
	};

	return (
		<React.Fragment>
			<div style={{ margin: '25px' }}>
				<div>
					<Row>
						<Col>
							<h2>Sju sjösjuka sjömän</h2>
						</Col>
						<Col>
							{' '}
							{!isSignedIn && (
								<FontAwesomeIcon
									onClick={() => (window.location.href = window.location.origin)}
									style={{ cursor: 'pointer' }}
									icon={faHome}
								/>
							)}
						</Col>
					</Row>
				</div>
				<p>
					Skottdagen 2020, den 29e Februari flyttar vi till denna fantastiska tvåa på 55m<sup>2</sup> i
					Masthugget
				</p>{' '}
			</div>
			<Container>
				<Row>
					{images.map((imgage, index) => (
						<Col xs={6} md={3} style={{ padding: '5px' }}>
							<img
								className="center-fit"
								alt="object"
								onClick={() => handleClick(++index)}
								src={imgage}
							/>
						</Col>
					))}
				</Row>
			</Container>
			<FsLightbox onClose={() => setToggler(!toggler)} toggler={toggler} sources={images} slide={currentIndex} />
		</React.Fragment>
	);
};

// const Sjomansgatan = () => {
// 	return (
// 		<div style={{ margin: '5px' }}>
// 			<div style={{ margin: '25px' }}>
// 				<h2>Sju sjösjuka sjömän</h2>
// 				<p>
// 					Skottdagen 2020, den 29e Februari flyttar vi till denna fantastiska tvåa på 55m<sup>2</sup> i
// 					Masthugget
// 				</p>{' '}
// 			</div>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img1} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img2} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img3} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img4} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img5} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img6} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img7} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img8} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img9} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img10} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img11} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img12} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img13} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img14} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img15} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img16} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img17} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img18} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img19} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img20} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img21} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={img22} />
// 			</p>
// 			<p className="imgbox">
// 				<img className="center-fit" alt="object" src={imgPlan} />
// 			</p>
// 		</div>
// 	);
// };

export default Sjomansgatan;
