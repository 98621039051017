import React, { useState, useEffect } from 'react';
import firebase from './firebaseUtils';

const database = firebase.firestore();

const Jul = ({ authUser, dbUser }) => {
	const [ prevGifting, setPrevGifting ] = useState(null);
	const [ currentGifting, setCurrentGifting ] = useState(null);
	const [ isLoading, setIsLoading ] = useState(true);

	useEffect(() => {
		setIsLoading(true);
		if (dbUser) {
			getPreviousGifting(dbUser.Name);
			getCurrentGifting(dbUser.Name);
		}
	}, []);

	const getPreviousGifting = async (user) => {
		let model = [];
		const year = (new Date().getUTCFullYear() - 1).toString();
		const query = await database.collection('Gifting').doc(year).get();

		if (query.exists) {
			model = query.data();
		}
		const preGift = model.Users.find((x) => x.From === user);
		if (preGift) {
			setPrevGifting(preGift);
		}
	};
	const getCurrentGifting = async (user) => {
		let model = [];
		const year = new Date().getUTCFullYear().toString();
		const query = await database.collection('Gifting').doc(year).get();

		if (query.exists) {
			model = query.data();
		}

		const currGift = model.Users.find((x) => x.From === user);
		if (currGift) {
			setCurrentGifting(currGift);
		}

		setIsLoading(false);
	};

	return (
		<div className="SantaDiv">
			<span>
				{isLoading === true ? (
					<div className="spinner-border" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				) : (
					<section>
						<div>
							<h1>Ho Ho Ho {dbUser.Name} !</h1>
							<div>
								<img
									className="imgGG"
									height="60"
									width="60"
									alt="profile picture"
									src={authUser.photoURL}
								/>
							</div>
							{currentGifting === null ? (
								<h1>Detta år finns det ingen du ska ge julklapp till</h1>
							) : (
								<h1>I år är din hemliga julklappsmottagare: {currentGifting.To}</h1>
							)}
							{prevGifting === null ? (
								<h5>Förra året var du inte med i Secret Santa</h5>
							) : (
								<div>
									<h4>En gåva för ungefär 200kr siktar vi på</h4>
									<h5>Förra året gav du till: {prevGifting.To}</h5>
								</div>
							)}
						</div>
					</section>
				)}
			</span>
		</div>
	);
};

export default Jul;
