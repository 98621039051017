import React, { useState, useEffect } from 'react';
import './App.css';
import Button from 'react-bootstrap/Button';
import BootstrapNavBar from 'react-bootstrap/Navbar';
import { Nav, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const Navbar = ({ handleOnSignOut }) => {
	return (
		<div className="App">
			<span>
				<BootstrapNavBar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
					<LinkContainer to="/">
						<BootstrapNavBar.Brand>Gerenmark</BootstrapNavBar.Brand>
					</LinkContainer>
					<BootstrapNavBar.Toggle aria-controls="responsive-navbar-nav" />
					<BootstrapNavBar.Collapse id="responsive-navbar-nav">
						<Nav className="mr-auto">
							<LinkContainer to="/">
								<Nav.Link>Home</Nav.Link>
							</LinkContainer>
							<LinkContainer to="/sjomansgatan">
								<Nav.Link>Sjömansgatan Sju</Nav.Link>
							</LinkContainer>
							<LinkContainer to="/jul">
								<Nav.Link>Secret Santa</Nav.Link>
							</LinkContainer>
						</Nav>
						<Nav>
							<NavDropdown title="Actions" id="collasible-nav-dropdown">
								<LinkContainer to="/adminss">
									<NavDropdown.Item>Admin SS</NavDropdown.Item>
								</LinkContainer>
								<LinkContainer to="/command">
									<NavDropdown.Item>Commandline</NavDropdown.Item>
								</LinkContainer>
								<NavDropdown.Divider />
								<Button onClick={handleOnSignOut}>Sign out</Button>
							</NavDropdown>
							<LinkContainer to="/more">
								<Nav.Link>More deets</Nav.Link>
							</LinkContainer>
						</Nav>
					</BootstrapNavBar.Collapse>
				</BootstrapNavBar>
			</span>
		</div>
	);
};

export default Navbar;
