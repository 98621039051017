import React, { useState, useEffect } from 'react';
import './App.css';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import AdminPage from './AdminPage';
import firebase from './firebaseUtils';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import Sjomansgatan from './Sjomansgatan';
import More from './More';
import Jul from './Jul';
import './firebaseui-styling.global.css'; // Import globally. Not with CSS modules.

const database = firebase.firestore();

const uiConfig = {
	signInFlow: 'popup',
	signInOptions: [
		firebase.auth.GoogleAuthProvider.PROVIDER_ID
		// firebase.auth.FacebookAuthProvider.PROVIDER_ID,
		// firebase.auth.TwitterAuthProvider.PROVIDER_ID,
		// firebase.auth.GithubAuthProvider.PROVIDER_ID,
		// firebase.auth.EmailAuthProvider.PROVIDER_ID
	],
	callbacks: {
		signInSuccess: () => false
	}
};

const App = () => {
	const [ isSignedIn, setSignedIn ] = useState(false);
	const [ isAnders, setIsAnders ] = useState(false);
	const [ isAdmin, setIsAdmin ] = useState(false);
	const [ authUser, setAuthUser ] = useState(null);
	const [ dbUser, setDbUser ] = useState(null);

	useEffect(() => {
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				setAuthUser(user);
				setSignedIn(!!user);
				getDbUser(user.email);
			}
		});
	}, []);

	useEffect(
		() => {
			if (dbUser) {
				if (dbUser.Name === 'Anders') {
					setIsAnders(true);
				}
				if (dbUser.Name === 'Anders' || dbUser.Name === 'Veronica') {
					setIsAdmin(true);
				}
			}
		},
		[ dbUser ]
	);

	const handleOnSignOut = () => {
		setAuthUser(null);
		setSignedIn(false);
		setDbUser(null);
		firebase.auth().signOut();
	};

	const getDbUser = async (email) => {
		const query = await database.collection('Users').where('Email', '==', email).get();
		query.forEach((x) => setDbUser(x.data()));
	};

	if (!isSignedIn && !window.location.pathname.toLowerCase().includes('sjomansgatan')) {
		return (
			<div className="LoginStyle">
				<div style={{ height: '40px' }} />
				<StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
			</div>
		);
	} else if (window.location.pathname.toLowerCase().includes('sjomansgatan')) {
		return <Sjomansgatan isSignedIn={isSignedIn} />;
	}

	return (
		<React.Fragment>
			<BrowserRouter>
				<React.Fragment>{isSignedIn && <Navbar handleOnSignOut={handleOnSignOut} />}</React.Fragment>
				<Switch>
					{isSignedIn && <Route path={'/'} exact component={Home} />}
					{isSignedIn &&
					dbUser && (
						<Route path={'/jul'} exact component={() => <Jul authUser={authUser} dbUser={dbUser} />} />
					)}
					{isSignedIn && (
						<Route
							path={'/sjomansgatan'}
							exact
							component={() => <Sjomansgatan isSignedIn={isSignedIn} />}
						/>
					)}
					{isAnders && <Route path={'/adminss'} component={AdminPage} />}
					{isSignedIn && <Route path={'/more'} component={More} />}
				</Switch>
			</BrowserRouter>
		</React.Fragment>
	);
};

export default App;
