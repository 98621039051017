import React, { useState, useEffect } from 'react';
import './App.css';
import img1 from './img/1.jpg';
import santa from './img/santa2.jpeg';
import { LinkContainer } from 'react-router-bootstrap';

const Home = () => {
	return (
		<div style={{ margin: '40px' }}>
			<h2>Home</h2>
			<p>There is nothing here.. maybe you should visit:</p>

			<p>
				<LinkContainer to="/Sjomansgatan">
					<img className="imglink" height="100px" alt="object" src={img1} />
				</LinkContainer>
			</p>
			<p>
				<LinkContainer to="/Jul">
					<img className="imglink" height="100px" alt="object" src={santa} />
				</LinkContainer>
			</p>
		</div>
	);
};

export default Home;
