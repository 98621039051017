import React, { useState, useEffect } from 'react';
import firebase from './firebaseUtils';
import { Button, InputGroup, FormControl, Table, Form } from 'react-bootstrap';

const database = firebase.firestore();

const AdminPage = () => {
	const [ users, setUsers ] = useState(null);
	const [ prevGiftings, setPrevGiftings ] = useState(null);
	const [ errorMessage, setErrorMessage ] = useState(null);
	let currentGifting = [];
	let mainLoop = true;

	const getUsers = async () => {
		let result = [];
		const userQuery = await database.collection('Users').get();
		userQuery.forEach((x) => result.push(x.data()));

		const usersModel = result.map((user) => ({ ...user, checked: true }));

		setUsers(usersModel);
	};
	const getPrevGiftings = async () => {
		let result = [];

		const giftQuery = await database
			.collection('Gifting')
			.where('Date', '==', new Date().getUTCFullYear() - 1)
			.get();
		giftQuery.forEach((x) => result.push(x.data()));
		setPrevGiftings(result);
	};

	useEffect(() => {
		getUsers();
		getPrevGiftings();
	}, []);

	const handleChange = (user) => {
		const copyUsers = users.map((user) => ({ ...user }));
		const userPressed = copyUsers.find((x) => x.Name === user.Name);

		userPressed.checked = !userPressed.checked;
		setUsers(copyUsers);
	};

	const handleSave = () => {
		setErrorMessage(null);
		mainLoop = true;
		currentGifting = [];
		let result = [];
		let runs = 0;
		var chekedUsers = users.filter((user) => user.checked);
		while (mainLoop) {
			console.log('started loop');
			runs++;
			try {
				result = startGenerating(chekedUsers);
			} catch (error) {
				currentGifting = [];
				if (runs === 99) {
					setErrorMessage('Couldnt match within 99 attempts');
					return;
				}
			}
		}

		console.table(result);
		const currentYear = new Date().getUTCFullYear().toString();
		database
			.collection('Gifting')
			.doc(currentYear)
			.set({ Users: result })
			.then((res) => {
				console.log('Document successfully written!');
			})
			.catch((error) => {
				console.error('Error writing document: ', error);
			});
	};

	const startGenerating = (chekedUsers) => {
		let result = [];

		while (true) {
			chekedUsers.forEach((checkedUser) => {
				const giftingPartner = getRandomGiftingPartner(checkedUser, chekedUsers);
				if (giftingPartner !== null) {
					const model = {
						Date: new Date().getUTCFullYear(),
						From: checkedUser.Name,
						To: giftingPartner.Name
					};
					result.push(model);
				} else {
					throw Error('error');
				}
			});

			//Succedeed
			mainLoop = false;
			return result;
		}
	};

	const getRandomGiftingPartner = (user, users) => {
		let possibleUsersToGift = [];
		const prevGiftingPartner = prevGiftings.find((pg) => pg.From === user.Name);

		//Remove self from list
		possibleUsersToGift = users.filter((c) => c.Name !== user.Name);

		//Remove Partner from list
		if (user.Partner) {
			possibleUsersToGift = possibleUsersToGift.filter((c) => c.Name && c.Name !== user.Partner);
		}

		//Remove partner from list
		if (prevGiftingPartner) {
			possibleUsersToGift = possibleUsersToGift.filter((c) => c.Name !== prevGiftingPartner.To);
		}

		//Remove users already gifted from list
		if (currentGifting.length > 0) {
			possibleUsersToGift = possibleUsersToGift.filter((el) => !currentGifting.includes(el));
		}

		const userToGift = possibleUsersToGift[Math.floor(Math.random() * possibleUsersToGift.length)];
		currentGifting.push(userToGift);

		return userToGift;
	};

	return (
		<div style={{ width: '350px', margin: '30px auto' }}>
			<Table size="sm" hover responsive>
				<thead>
					<tr>
						<th>Name</th>
						<th>Checked</th>
					</tr>
				</thead>
				<tbody>
					{users &&
						users.map((user) => (
							<tr>
								<td>{user.Name}</td>
								<td>
									<Form.Check checked={user.checked} onChange={() => handleChange(user)} />
								</td>
							</tr>
						))}
				</tbody>
			</Table>
			<Button onClick={handleSave} style={{ margin: '10px ' }}>
				Randomize Secret Santa for this year
			</Button>
			{errorMessage && <p>{errorMessage}</p>}
		</div>
	);
};

export default AdminPage;
