import React, { useState, useEffect } from 'react';
import './App.css';

const More = () => {
	return (
		<div style={{ margin: '40px' }}>
			<h2>More information</h2>
			<p>Null</p>{' '}
		</div>
	);
};

export default More;
